import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import contactImg from "../images/contact/contact.png"
import angleRight from "../images/common/angle-right-white.svg"
import emailjs from "emailjs-com"
import Rodal from "rodal"
import xIcon from "../images/common/x.svg"
import seoContent from "../helpers/seoContent"

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      emailValid: false,
      nameValid: false,
      phoneValid: false,
      messageValid: false,
      hasSubmitted: false,
      hasSent: false,
      sendSuccess: false,
      loading: false,
    }
  }

  updateValue(key, e) {
    const { value } = e.target
    this.setState({
      [key]: value,
    })
  }

  validationCheck() {
    const isNameValid =
      this.state.name &&
      this.state.name.trim() &&
      this.state.name.trim().length > 0
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    const trimmedEmail = this.state.email && this.state.email.trim()
    const isEmailValid = emailRegex.test(trimmedEmail)
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    const isPhoneValid = !(this.state.phone && this.state.phone.trim())
      ? true
      : phoneRegex.test(this.state.phone && this.state.phone.trim())
    const isMessageValid =
      this.state.message &&
      this.state.message.trim() &&
      this.state.message.trim().length > 0

    this.setState({
      nameValid: isNameValid,
      emailValid: isEmailValid,
      phoneValid: isPhoneValid,
      messageValid: isMessageValid,
    })

    const isValid =
      isNameValid && isEmailValid && isPhoneValid && isMessageValid
    return isValid ? true : false
  }

  resetState() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      message: "",
      emailValid: false,
      nameValid: false,
      phoneValid: false,
      messageValid: false,
      hasSubmitted: false,
      hasSent: false,
      sendSuccess: false,
      loading: false,
    })
  }

  sendContact(e) {
    e.preventDefault()
    const isValid = this.validationCheck(
      this.state.name,
      this.state.email,
      this.state.phone,
      this.state.message
    )
    if (!isValid) {
      this.setState({
        hasSubmitted: true,
        hasSent: false,
        loading: false,
      })
      return
    } else {
      this.setState({
        hasSubmitted: true,
        hasSent: true,
        loading: true,
      })
    }

    const emailjs_ID = "user_4IrCCqjxS1OunMCmcsB9m"
    const template_params = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    }
    const service_id = "website_contact_form_email"
    const template_id = "template_BgZAkO6G"

    emailjs.send(service_id, template_id, template_params, emailjs_ID).then(
      res => {
        this.resetState()
        this.setState({
          sendSuccess: true,
          loading: false,
        })
      },
      err => {
        this.setState({
          sendSuccess: false,
          loading: false,
        })
      }
    )
  }

  closePopup() {
    this.resetState()
  }

  renderPopup() {
    return (
      <div className="contact-modal">
        <img
          className="contact-modal-close"
          src={xIcon}
          onClick={this.closePopup.bind(this)}
        />
        <div className="contact-modal-title">
          {this.state.sendSuccess
            ? "Success, delivered!"
            : "Error, something went wrong."}
        </div>
        <div className="contact-modal-text">
          {this.state.sendSuccess
            ? "Thanks for reaching out! Gabriella will get back to you soon."
            : "Please try again or email gabriella@alziari.co directly instead."}
        </div>
      </div>
    )
  }

  render() {
    const borderStyles = {
      error: {
        border: "1px solid #f96e6e",
      },
      default: {
        border: "1px solid #31b7b7",
      },
    }

    const getValidState = type => {
      const {
        nameValid,
        emailValid,
        phoneValid,
        messageValid,
        hasSubmitted,
      } = this.state
      if (type === "name") {
        return !nameValid && hasSubmitted
          ? borderStyles.error
          : borderStyles.default
      } else if (type === "email") {
        return !emailValid && hasSubmitted
          ? borderStyles.error
          : borderStyles.default
      } else if (type === "phone") {
        return !phoneValid && hasSubmitted
          ? borderStyles.error
          : borderStyles.default
      } else if (type === "message") {
        return !messageValid && hasSubmitted
          ? borderStyles.error
          : borderStyles.default
      }
    }

    const { siteDescriptions } = seoContent
    return (
      <Layout>
        <SEO title="Contact" description={siteDescriptions.default} />
        <div id="contact">
          <div className="contact-header">
            <h1>Contact</h1>
          </div>
          <div className="contact-body">
            <Rodal
              visible={
                this.state.sendSuccess ||
                (this.state.hasSent && !this.state.loading)
              }
              onClose={this.closePopup.bind(this)}
              animation={"fade"}
              showCloseButton={false}
            >
              {this.renderPopup()}
            </Rodal>
            <div
              className="contact-modal-loading-wrap"
              style={
                this.state.loading ? { display: "block" } : { display: "none" }
              }
            >
              <i className="contact-modal-loading fas fa-yin-yang fa-spin"></i>
            </div>
            <div className="contact-body-content">
              <img className="contact-img" src={contactImg} />
              <form
                className="contact-form"
                onSubmit={this.sendContact.bind(this)}
              >
                <div className="contact-input-container">
                  <div className="contact-input-text">Name</div>
                  <div
                    className="contact-input-wrap"
                    style={getValidState("name")}
                  >
                    <input
                      className="contact-input"
                      type="text"
                      value={this.state.name}
                      onChange={this.updateValue.bind(this, "name")}
                    />
                  </div>
                </div>
                <div className="contact-input-container">
                  <div className="contact-input-text">Email</div>
                  <div
                    className="contact-input-wrap"
                    style={getValidState("email")}
                  >
                    <input
                      className="contact-input"
                      type="text"
                      value={this.state.email}
                      onChange={this.updateValue.bind(this, "email")}
                    />
                  </div>
                </div>
                <div className="contact-input-container">
                  <div className="contact-input-text">Phone (optional)</div>
                  <div
                    className="contact-input-wrap"
                    style={getValidState("phone")}
                  >
                    <input
                      className="contact-input"
                      type="text"
                      value={this.state.phone}
                      onChange={this.updateValue.bind(this, "phone")}
                    />
                  </div>
                </div>
                <div className="contact-input-container">
                  <div className="contact-input-text">Message</div>
                  <div
                    className="contact-input-wrap contact-input-wrap-textarea"
                    style={getValidState("message")}
                  >
                    <textarea
                      className="contact-input contact-textarea"
                      type="text"
                      value={this.state.message}
                      onChange={this.updateValue.bind(this, "message")}
                    />
                  </div>
                </div>
                <div className="contact-submit-row">
                  <div
                    className="contact-submit-wrap"
                    onClick={this.sendContact.bind(this)}
                  >
                    <div className="contact-submit-text">Submit</div>
                    <input
                      className="contact-form-submit"
                      type="submit"
                      value=""
                    />
                    <img className="angle-right" src={angleRight} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
